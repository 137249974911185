import EventIcon from '@mui/icons-material/Event';
import ParkingIcon from '@mui/icons-material/LocalParking';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import WarningIcon from '@mui/icons-material/Warning';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';

const Info = () => (
  <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'primary' }}>
    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <Avatar>
          <WarningIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary="Autoloze zondag"
        secondary={
          <Link href="https://www.dezomerisvanmechelen.be/evenement/autovrije-zondag/info">
            dezomerisvanmechelen.be
          </Link>
        }
      />
    </ListItem>

    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <Avatar>
          <EventIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary="Wanneer" secondary="18 September | 14u-18u" />
    </ListItem>

    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <Avatar>
          <LocationCityIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary="Waar"
        secondary={
          <Link href="https://goo.gl/maps/VoPo9phcno9g7Uad8" target="_blank">
            Moreelstraat 3, 2800 Mechelen
          </Link>
        }
      />
    </ListItem>

    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <Avatar>
          <ParkingIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary="Parking"
        secondary={
          <Link href="https://goo.gl/maps/c85ys3AoEDTewEEp7" target="_blank">
            Rode-Kruisplein, 2800 Mechelen
          </Link>
        }
      />
    </ListItem>
  </List>
);

export default React.memo(Info);
