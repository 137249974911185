import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Section from '../../components/Section';
import BabyShower from './BabyShower';
import Home from './Home';

const Client = () => {
  return (
    <Routes>
      <Route path="/" element={<Section />}>
        <Route index element={<Home />} />
        <Route path="babyborrel" element={<BabyShower />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  );
};

export default React.memo(Client);
