import ArrowBackIcon from '@mui/icons-material/ArrowCircleLeft';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Link } from 'react-router-dom';

import useModal from './hooks/useModal';
import Info from './Info';
import Subsribe from './Subscribe';

const BabyShower = () => {
  const { isBabyShowerModalOpen, toggleModal } = useModal();
  return (
    <>
      <Stack
        id="babyborelInfo"
        spacing={3}
        justifyContent="center"
        alignItems="center"
      >
        <Grid container>
          <Grid item>
            <IconButton component={Link} to="/">
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography variant="h4" color="primary">
              Babyborrel info
            </Typography>
          </Grid>
        </Grid>
        <Info />
        <Button onClick={toggleModal} variant="contained">
          Inschrijven
        </Button>
      </Stack>
      <Subsribe isModalOpen={isBabyShowerModalOpen} toggleModal={toggleModal} />
    </>
  );
};

export default React.memo(BabyShower);
