import React from 'react';

export type FormData = { name: string; totalHeads: number };

const useFormData = (onSubmit: (data: FormData) => Promise<void>) => {
  const [name, setName] = React.useState<string>('');
  const [totalHeads, setTotalHeads] = React.useState<string>('1');
  const [errorCode, setErrorCode] = React.useState(0);
  const totalHeadsNum = Number(totalHeads);

  const handleNameChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setName(value);
    },
    [],
  );

  const handleTotalHeadsChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setTotalHeads(value);
    },
    [],
  );

  const handleSubmit = React.useCallback(
    async (e: React.FormEvent) => {
      try {
        e.preventDefault();
        if (!name) {
          throw new Error('1');
        }
        if (Number.isNaN(totalHeadsNum) || totalHeadsNum <= 0) {
          throw new Error('2');
        }
        await onSubmit({ name, totalHeads: totalHeadsNum });
        setName('');
        setTotalHeads('');
        setErrorCode(0);
      } catch (error) {
        const errorAsErrorCode = Number((error as Error).message);
        if (Number.isNaN(errorAsErrorCode)) {
          setErrorCode(3);
          return;
        }
        setErrorCode(errorAsErrorCode);
      }
    },
    [name, onSubmit, totalHeadsNum],
  );

  const contentText = React.useMemo(() => {
    switch (errorCode) {
      case 1:
        return 'Naam is een verplicht veld.';
      case 2:
        return 'Aantal Personen moet minstens 1 zijn.';
      case 3:
        return 'Er ging iets mis bij de inschrijving. Gelieve contact op te nemen met Jens (jens.cappelle@telenet.be)';
      default:
        return `Zeg ons je naam en laat ons weten met hoeveel personen (jijzelf
            inbegrepen) je zal komen.`;
    }
  }, [errorCode]);

  return {
    hasError: !!errorCode,
    name,
    totalHeads,
    handleNameChange,
    handleTotalHeadsChange,
    handleSubmit,
    contentText,
  };
};

export default useFormData;
