import { Amplify } from 'aws-amplify';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
// eslint-disable-next-line import/extensions
import awsExports from './aws-exports.js';

Amplify.configure(awsExports);

const root = ReactDOM.createRoot(document.getElementById('app') as HTMLElement);
root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);
