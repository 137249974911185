import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import React from 'react';

import { createGuest } from '../../../graphql/mutations';
import type { FormData } from './hooks/useFormData';
import useFormData from './hooks/useFormData';

type SubsribeProps = {
  isModalOpen: boolean;
  toggleModal: () => void;
};

const Subsribe = ({ isModalOpen, toggleModal }: SubsribeProps) => {
  const addGuest = React.useCallback(
    async ({ name, totalHeads }: FormData) => {
      await API.graphql(
        graphqlOperation(createGuest, {
          input: {
            name,
            totalHeads,
          },
        }),
      );
      toggleModal();
    },
    [toggleModal],
  );

  const {
    contentText,
    handleNameChange,
    handleSubmit,
    handleTotalHeadsChange,
    hasError,
    name,
    totalHeads,
  } = useFormData(addGuest);

  return (
    <Dialog open={isModalOpen} onClose={toggleModal}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Inschrijven</DialogTitle>
        <DialogContent>
          <DialogContentText {...(hasError && { color: 'red' })}>
            {contentText}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Naam"
            type="text"
            fullWidth
            variant="standard"
            value={name}
            onChange={handleNameChange}
          />
          <TextField
            autoFocus
            margin="dense"
            id="totalHeads"
            label="Aantal Personen"
            type="number"
            fullWidth
            variant="standard"
            value={totalHeads}
            onChange={handleTotalHeadsChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleModal}>Cancel</Button>
          <Button type="submit">Inschrijven</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default React.memo(Subsribe);
