import React from 'react';

const useModal = () => {
  const [isBabyShowerModalOpen, setIsBabyShowerModalOpen] =
    React.useState(false);

  const toggleModal = React.useCallback(() => {
    setIsBabyShowerModalOpen(
      (isBabyShowerModalOpenPrev) => !isBabyShowerModalOpenPrev,
    );
  }, []);

  return {
    isBabyShowerModalOpen,
    toggleModal,
  };
};

export default useModal;
