import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import GusBedjeImg from '../../../images/gus_bedje.jpg';
import LogoImg from '../../../images/logo.png';

const Logo = styled.img`
  width: 20%;
  height: auto;
`;

const Image = styled.img`
  width: 50%;
  height: auto;
  border-radius: 0.5rem;
`;

const Home = () => {
  return (
    <Stack spacing={3} justifyContent="center" alignItems="center">
      <Stack spacing={3} justifyContent="center" alignItems="center">
        <Logo src={LogoImg} />
        <Image src={GusBedjeImg} />
        <Typography variant="h4" color="primary">
          Gus Jean Cappelle
        </Typography>
        <Typography variant="body1" color="primary">
          15/07/2022
        </Typography>
      </Stack>
      <Stack spacing={1} justifyContent="center" alignItems="stretch">
        <Button
          href="https://atelierbebe.geboortelijst.be/"
          target="_blank"
          variant="contained"
        >
          Geboortelijst bekijken
        </Button>
        <Button to="babyborrel" component={Link} variant="contained">
          Babyborrel
        </Button>
      </Stack>
    </Stack>
  );
};

export default React.memo(Home);
