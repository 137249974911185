import '@fontsource/roboto';

import { createTheme, ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Client from './views/Client';

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(35%, 24%, 13%)',
      contrastText: 'white',
    },
  },
});

const App = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<Client />} />
      </Routes>
    </BrowserRouter>
  </ThemeProvider>
);

export default React.memo(App);
