import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

type SectionProps = React.PropsWithChildren<{
  isOdd?: boolean;
}>;

const Wrapper = styled(Box)<{ $isOdd: boolean }>`
  min-height: 100vh;
  width: 100vw;
  background-color: rgb(243, 236, 230);
  padding: 1rem 0;
`;

const Section = ({ isOdd = false }: SectionProps) => (
  <Wrapper $isOdd={isOdd}>
    <Container maxWidth="sm">
      <Outlet />
    </Container>
  </Wrapper>
);

export default React.memo(Section);
